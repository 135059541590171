import {mdiLogout, mdiReload} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid, List, ListItem,
    MenuItem, Paper,
    Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Tooltip,
} from '@mui/material'
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {Dayjs} from 'dayjs'
import React from 'react'
import {useParams} from 'react-router-dom'
import {GeoRestrictionApi} from '../../api/geo_restrictions_api'
import {StructureApi} from '../../api/structures_api'
import {UserApi} from '../../api/users_api'
import {User} from '../../interfaces/user_interface'
import Areyousure from "../../components/Popup/Areyousure/Areyousure";

export function EditionUserMiddleWare(): React.ReactElement {
    return <EditionUser params={useParams()}/>
}


export interface EditionProps {
    params?: any
}

interface EditionState {
    zones: any // Ensemble des géorestriction
    structures: any
    selected_zones: Set<number> // Ensemble des géorestriction de l'utilisateur
    user: User

    // TODO : renommer ce champs pour lui donner un nom plus explicite
    user_exist: string

    message: string

    // TODO : champ inutilisé
    structure_name: string

    // TODO : champ inutilisé
    role_name: string

    is_loaded: boolean

    action_user_id: number

    delete: boolean
}

export class EditionUser extends React.Component<EditionProps, EditionState> {
    constructor(props: EditionProps) {
        super(props)
        this.state = {
            is_loaded: false,
            message: '',
            role_name: 'Role dans la structure',
            selected_zones: new Set(),
            structure_name: 'Structure',
            structures: [],
            user: {
                id: 0,
                firstname: '',
                lastname: '',
                email: '',
                maillingAddress: '',
                phoneNumber: '',
                isAdmin: false,
                isBetaTest: false,
                isCnpf: false,
                isActive: true,
                numDevicesAuthorized: 0,
                structureRole: 'UNIQUE',
                expireOn: new Date().getTime() / 1000,
                structureId: 1,
                devices: [],
            },
            user_exist: '',
            zones: [],
            action_user_id: NaN,
            delete: false,

        }
        this.changeDate = this.changeDate.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            // Attention ambiguité entre zoneList et zones_list...
            const zoneList = await GeoRestrictionApi.listGeoRestrictions()
            const structureList = await StructureApi.listStructures()

            this.setState({zones: zoneList, structures: structureList})

            if (this.props.params) {
                const params = this.props.params['*'].split('/')
                const id = params[2]
                const user = await UserApi.getUser(Number(id))

                // const zones_list: number[] =
                // await GeoRestrictionApi.listGeoRestrictionsByUser(user.id)
                const zones_list = user.zones
                this.setState({
                    user: user,
                    user_exist: id,
                    selected_zones: new Set(zones_list),
                })
            }
            this.setState({is_loaded: true})
        } catch (e) {
            console.error(e)
            alert("Echec du chargement d'une des listes")
        }
    }

    toggleThisCheck(user_id: number) {
        if (this.getZoneCheckedStatus(user_id)) {
            this.uncheckZone(user_id)
        } else {
            this.checkZone(user_id)
        }
    }

    checkZone(geo_restriction_id: number) {
        this.setState(({selected_zones}) => ({
            selected_zones: new Set(selected_zones).add(geo_restriction_id),
        }))
    }

    uncheckZone(geo_restriction_id: number) {
        this.setState(({selected_zones}) => {
            const newChecked = new Set(selected_zones)
            newChecked.delete(geo_restriction_id)

            return {
                selected_zones: newChecked,
            }
        })
    }

    getZoneCheckedStatus(user_id: number) {
        return this.state.selected_zones.has(user_id)
    }

    changeDate(newDate: Dayjs | null) {
        if (newDate !== null) {
            this.setState(({user}) => {
                const edited_user = user
                edited_user.expireOn = newDate.toDate().getTime() / 1000
                return {user: edited_user}
            })
        }
    }

    /**
     * Gére la réinitialisation du mot passe d'un user déjà existant par l'admin
     * et l'initialisation du mot de passe de l'utilisateur nouvellement crée
     * @param userId
     */
    async handleAlertResetPassword(userId: any) {
        const response = await UserApi.resetPassword(userId)
        if (response.password) {
            alert(
                `Veuillez copier le nouveau mot de passe :\n${response.password}`
            )
        }
    }

    // TODO amélioration alert reset password : utiliser un modal cf. https://mui.com/material-ui/react-dialog/#AlertDialog.js
    // TODO amélioration alert reset password : ajouter copier/coller le mdp dans le presse-papier

    // TODO : simplifier le code : ajouter une clause if pour si is_loaded est à false,
    // faire un return spécifique
    render() {
        return (
            <Box sx={{p: 5}}>
                <Box sx={{borderBottom: 1}}>
                    {this.state.is_loaded && (
                        <h1>
                            {this.state.user_exist ? 'Édition ' : 'Création '}
                            d'un utilisateur
                        </h1>
                    )}
                </Box>

                {this.state.is_loaded ? (
                    <form
                        onSubmit={async (event) => {
                            // TODO : déplacer une méthode de classe
                            // TODO : prendre en charge les erreurs
                            event.preventDefault()

                            if (!this.state.user_exist) {
                                try {
                                    const response = await UserApi.createUser(
                                        this.state.user,
                                        Array.from(this.state.selected_zones),
                                        []
                                    )
                                    if(!this.state.user.isCnpf) {
                                        await this.handleAlertResetPassword(response.id)
                                    }
                                    window.location.href = '/utilisateurs'
                                } catch (e: any) {
                                    alert(e.message)
                                }

                            } else {
                                try {
                                    await UserApi.editUser(
                                        this.state.user,
                                        Array.from(this.state.selected_zones),
                                        []
                                    )

                                    window.location.href = '/utilisateurs'
                                } catch (e: any) {
                                    alert(e.message)
                                }
                            }

                        }}
                    >
                        {/* GRILLE EN TROIS COLONNES */}
                        <Grid container spacing={8} sx={{width: 1}}>
                            {/* PREMIERE COLONNE */}
                            <Grid item sx={{py: 2}} lg={4}>
                                <p
                                    style={{
                                        padding: '30px 0 10px 0',
                                        fontWeight: 800,
                                    }}
                                >
                                    Informations générales
                                </p>
                                {/* email */}
                                <Grid item sx={{py: 2}}>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        label="email"
                                        onChange={(event) => {
                                            this.setState(({user}) => {
                                                const edited_user = user
                                                edited_user.email =
                                                    event.target.value
                                                return {user: edited_user}
                                            })
                                        }}
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={this.state.user.email}
                                        placeholder=" "
                                        required
                                    />
                                </Grid>
                                {/* nom */}
                                <Grid container spacing={1}>
                                    <Grid item sx={{py: 2}} lg={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="nom"
                                            onChange={(event) => {
                                                // TODO : simplifier ce code
                                                this.setState(({user}) => {
                                                    const edited_user = user
                                                    edited_user.lastname =
                                                        event.target.value
                                                    return {user: edited_user}
                                                })
                                            }}
                                            type="text"
                                            name="last_name"
                                            id="first_name"
                                            value={this.state.user.lastname}
                                            placeholder=" "
                                            required
                                        />
                                    </Grid>
                                    {/* prenom */}
                                    <Grid item sx={{py: 2}} lg={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="prénom"
                                            onChange={(event) => {
                                                // TODO : simplifier ce code
                                                this.setState(({user}) => {
                                                    const edited_user = user
                                                    edited_user.firstname =
                                                        event.target.value
                                                    return {user: edited_user}
                                                })
                                            }}
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            value={this.state.user.firstname}
                                            placeholder=" "
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{py: 2}} spacing={1}>
                                    <Grid item lg={6}>
                                        {/* structure select */}
                                        <FormControl sx={{width: 1}}>
                                            <Select
                                                variant="standard"
                                                value={
                                                    this.state.user.structureId
                                                }
                                                onChange={(event) => {
                                                    // TODO : déplacer une méthode de classe
                                                    this.setState(
                                                        ({user}) => {
                                                            const edited_user =
                                                                user
                                                            edited_user.structureId =
                                                                Number(
                                                                    event.target
                                                                        .value
                                                                )
                                                            return {
                                                                user: edited_user,
                                                            }
                                                        }
                                                    )
                                                }}
                                            >
                                                {this.state.structures.map(
                                                    (structure: any) => (
                                                        <MenuItem
                                                            key={structure.id}
                                                            value={structure.id}
                                                        >
                                                            {structure.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* Role select */}
                                    <Grid item lg={6}>
                                        <FormControl sx={{width: 1}}>
                                            <Select
                                                value={
                                                    this.state.user
                                                        .structureRole
                                                }
                                                sx={{color: '#000000'}}
                                                variant="standard"
                                                onChange={(event) => {
                                                    // TODO : simplifier ce code
                                                    this.setState(
                                                        ({user}) => {
                                                            const edited_user =
                                                                user
                                                            if (
                                                                event.target
                                                                    .value ===
                                                                'UNIQUE' ||
                                                                event.target
                                                                    .value ===
                                                                'NATIONAL' ||
                                                                event.target
                                                                    .value ===
                                                                'GESTION_PROJET'
                                                            ) {
                                                                edited_user.structureRole =
                                                                    event.target.value
                                                            }
                                                            return {
                                                                user: edited_user,
                                                            }
                                                        }
                                                    )
                                                }}
                                                label="Role"
                                            >
                                                <MenuItem value={'UNIQUE'}>
                                                    Unique
                                                </MenuItem>
                                                <MenuItem
                                                    value={'GESTION_PROJET'}
                                                >
                                                    Gestion Projets
                                                </MenuItem>
                                                <MenuItem
                                                    value={'GESTION_NATIONALE'}
                                                >
                                                    Gestion Nationale
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{py: 2}} spacing={1}>
                                    <Grid item sx={{py: 2}} lg={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Num Tél"
                                            onChange={(event) => {
                                                this.setState(({user}) => {
                                                    const edited_user = user
                                                    edited_user.phoneNumber =
                                                        event.target.value
                                                    return {user: edited_user}
                                                })
                                            }}
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            value={this.state.user.phoneNumber}
                                            placeholder=""
                                        />
                                    </Grid>
                                    <Grid item sx={{py: 2}} lg={6}>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            label="Adresse Postale"
                                            onChange={(event) => {
                                                this.setState(({user}) => {
                                                    const edited_user = user
                                                    edited_user.maillingAddress =
                                                        event.target.value
                                                    return {user: edited_user}
                                                })
                                            }}
                                            type="text"
                                            name="mailling_address"
                                            id="mailling_address"
                                            value={
                                                this.state.user.maillingAddress
                                            }
                                            placeholder=""
                                        />
                                    </Grid>
                                </Grid>
                                {/* BOOLEANS actif admin beta cnpf */}
                                <Grid container spacing={1}>
                                    <Grid item lg={6}>
                                        <FormGroup>
                                            {[
                                                {
                                                    bool: this.state.user
                                                        .isActive,
                                                    name: 'Compte actif',
                                                    function: () => {
                                                        // TODO : simplifier ce code
                                                        this.setState(
                                                            ({user}) => {
                                                                const edited_user =
                                                                    user
                                                                edited_user.isActive =
                                                                    !user.isActive
                                                                return {
                                                                    user: edited_user,
                                                                }
                                                            }
                                                        )
                                                    },
                                                },
                                                {
                                                    bool: this.state.user
                                                        .isCnpf,
                                                    name: 'Membre du CNPF',
                                                    function: () => {
                                                        this.setState(
                                                            ({user}) => {
                                                                const edited_user =
                                                                    user
                                                                edited_user.isCnpf =
                                                                    !user.isCnpf
                                                                return {
                                                                    user: edited_user,
                                                                }
                                                            }
                                                        )
                                                    },
                                                },
                                                {
                                                    bool: this.state.user
                                                        .isBetaTest,
                                                    name: 'Beta testeur',
                                                    function: () => {
                                                        this.setState(
                                                            ({user}) => {
                                                                const edited_user =
                                                                    user
                                                                edited_user.isBetaTest =
                                                                    !user.isBetaTest
                                                                return {
                                                                    user: edited_user,
                                                                }
                                                            }
                                                        )
                                                    },
                                                },
                                                {
                                                    bool: this.state.user
                                                        .isAdmin,
                                                    name: 'Administrateur',
                                                    function: () => {
                                                        this.setState(
                                                            ({user}) => {
                                                                const edited_user =
                                                                    user
                                                                edited_user.isAdmin =
                                                                    !user.isAdmin
                                                                return {
                                                                    user: edited_user,
                                                                }
                                                            }
                                                        )
                                                    },
                                                },
                                            ].map((account, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            onChange={
                                                                account.function
                                                            }
                                                            checked={
                                                                account.bool
                                                            }
                                                        />
                                                    }
                                                    sx={{
                                                        color: 'text.primary',
                                                    }}
                                                    label={account.name}
                                                />
                                            ))}
                                        </FormGroup>
                                    </Grid>
                                    {/* Renew password  */}
                                    <Grid item lg={6}>
                                        {this.state.user_exist &&
                                        !this.state.user.isCnpf ? (
                                            <Box>
                                                <Button
                                                    sx={{
                                                        diaplay: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={async (event) => {
                                                        event.preventDefault()
                                                        await this.handleAlertResetPassword(
                                                            this.state.user.id
                                                        ).then()
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            padding:
                                                                '30px 0 10px 0',
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        Réinitialiser le mot de
                                                        passe
                                                    </p>
                                                    <Icon path={mdiReload}/>
                                                </Button>
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* DEUXIEME COLONNE */}
                            {/* Date Picker */}
                            <Grid item lg={4} sx={{py: 2}}>
                                <p
                                    style={{
                                        padding: '30px 0 10px 0',
                                        fontWeight: 800,
                                    }}
                                >
                                    Licence
                                </p>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DesktopDatePicker
                                        label="date"
                                        inputFormat="MM/DD/YYYY"
                                        // TODO : date en temps unix seconde
                                        value={this.state.user.expireOn * 1000}
                                        onChange={this.changeDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{py: 2}}
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                {/* Appareils autorisés */}
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Nombre d'appareils autorisé"
                                    onChange={(event) => {
                                        this.setState(({user}) => {
                                            const edited_user = user
                                            edited_user.numDevicesAuthorized =
                                                Number(event.target.value)
                                            return {user: edited_user}
                                        })
                                    }}
                                    type="number"
                                    name="expiration"
                                    id="expiration"
                                    placeholder=" "
                                    value={this.state.user.numDevicesAuthorized}
                                    required
                                />
                                {/* Tableau Devices */}
                                <TableContainer component={Paper} sx={{marginTop: 5}}>
                                    <Table>
                                        <TableHead
                                            sx={{
                                                backdropFilter: 2,
                                                backgroundColor: 'primary.main',
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Nom de l'appareil
                                                </TableCell>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Dernière utilisation
                                                </TableCell>
                                                <TableCell sx={{color: '#ffffff'}}>

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {!(this.state.user.devices?.length) ? (
                                                <TableRow sx={{color: '#ffffff', p: 5}}>
                                                    <TableCell colSpan={2}>
                                                        Aucun appareil connecté à cet utilisateur
                                                    </TableCell>
                                                </TableRow>
                                            ) : (

                                                this.state.user.devices
                                                    .sort((a, b) => a.lastUsed < b.lastUsed ? 1 : -1)
                                                    .map((device: any) => (
                                                        <TableRow
                                                            key={device.id}
                                                            sx={{
                                                                '&:hover': {
                                                                    backgroundColor: '#0084730c',
                                                                },
                                                            }}
                                                        >
                                                            <TableCell sx={{py: 0}}>
                                                                {device.name}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {new Date(
                                                                    device.lastUsed * 1000
                                                                ).toLocaleDateString()}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                <Box>
                                                                    <List>
                                                                        <ListItem sx={{p: 0, my: 1}}>
                                                                            <Tooltip
                                                                                title="Déconnecter"
                                                                                placement="right"
                                                                            >
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            action_user_id:
                                                                                            device.id,
                                                                                            delete: true,
                                                                                        })
                                                                                    }}
                                                                                    sx={{p: 0, px: 1}}
                                                                                    color="error"
                                                                                >
                                                                                    <Icon
                                                                                        path={mdiLogout}
                                                                                    />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </ListItem>
                                                                    </List>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>)))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {this.state.delete ? (
                                    <Areyousure
                                        message="supprimer un appareil"
                                        action={async () => {
                                            await UserApi.deleteAdminDevice(this.state.user.id, this.state.action_user_id)
                                            window.location.reload();
                                        }}
                                        close={() => {
                                            this.setState({delete: false})
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            {/* TROISIEME COLONNE */}
                            <Grid item sx={{py: 2}}>
                                {/* Checkbox zones autorisées */}
                                <FormGroup>
                                    <p
                                        style={{
                                            padding: '30px 0 10px 0',
                                            fontWeight: 800,
                                        }}
                                    >
                                        Zones Autorisées
                                    </p>
                                    {this.state.zones.map((zone: any) => (
                                        <FormControlLabel
                                            key={zone.id}
                                            label={zone.name}
                                            control={
                                                <Checkbox
                                                    onChange={() => {
                                                        this.toggleThisCheck(
                                                            zone.id
                                                        )
                                                    }}
                                                    checked={this.getZoneCheckedStatus(
                                                        zone.id
                                                    )}
                                                />
                                            }
                                        ></FormControlLabel>
                                    ))}
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Button variant="contained" type="submit">
                            Valider
                        </Button>
                    </form>
                ) : (
                    <Box
                        sx={{
                            width: 1,
                            height: '500px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress/>
                    </Box>
                )}
            </Box>
        )
    }
}
